import React from "react";
import { useCookies } from "react-cookie";
import NumberGrid from "./NumberGrid";

function Favorite({ handleBuyClick }) {
  const [cookies, setCookie] = useCookies(["cart"]);

  const handleLinkClick = (event, number) => {
    event.preventDefault();
    const updatedCart = Array.isArray(cookies.cart) ? cookies.cart.filter(item => item.sim !== number.sim) : [];
    if (cookies.cart === undefined) {
      setCookie("cart", [number]);
    } else {
      if (updatedCart.length === cookies.cart.length) {
        updatedCart.push(number);
      }
      setCookie("cart", updatedCart);
    }
  };
  return (
    <div className="row">
      {cookies.cart && cookies.cart.map((number, index) => (
        <div className="col-lg-4 col-md-6 mt-4">
          <NumberGrid number={number} index={index} handleBuyClick={handleBuyClick} handleLinkClick={handleLinkClick} cookies={cookies} />
        </div>))}
      {/*end job-box*/}
      <div className="text-center mt-4 pt-2">
        {/* <a href="job-list.html" className="btn btn-primary">
          Daha Çox <i className="uil uil-arrow-right"></i>
        </a> */}
      </div>
      {/*end recent-jobs-tab*/}
    </div>
  )
}

export default Favorite