import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid'

function SimaScan() {
    // const operationId = JSON.parse(localStorage.getItem('uuid')) || uuid();
    const [id, setId] = useState(JSON.parse(localStorage.getItem('uuid')) || uuid())
    localStorage.setItem('uuid', JSON.stringify(id))
    const [status, setStatus] = useState(0);
    const [qrLink, setQrLink] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const checkIfMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        checkIfMobile();


        const handleScan = async (operId) => {
            try {
                const response = await axios.post('https://mynumber.az/simaa/qrcode.php', { operationId: operId });
                const data = response.data;
                setQrLink(data.qrUrl);
            } catch (error) {
                console.error('API çağırışı uğursuz oldu:', error);
            }
        };
            handleScan(id);


        const fetchStatus = async () => {
            try {
                const response = await axios.post('https://mynumber.az/simaa/statusAuth.php', { operationId: id });
                setStatus(response.data.status);
                console.log(status)
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        // İlk dəfə status məlumatını al
        fetchStatus();

        // Hər 5 saniyədə bir status məlumatını yenilə
        const intervalId = setInterval(fetchStatus, 5000);
        if (status === 1) {
            const fetchUser = async () => {
                try {
                    const response = await axios.post('https://mynumber.az/simaa/userInfo.php', { operationId: id });
                    localStorage.setItem('user', JSON.stringify(response.data.jwt));
                    localStorage.setItem('log', JSON.stringify(1))
                    window.location.reload()
                } catch (error) {
                    console.error('Error fetching status:', error);
                }
            };
            fetchUser();
            // window.location.reload();
        } else {
            localStorage.setItem('log', JSON.stringify(0))
        }
        // Component unmount olduqda intervalı təmizlə
        return () => clearInterval(intervalId);

    }, [status,id])
    return (
        <div>
            <div
                className="modal fade"
                id="simaScan"
                tabIndex="-1"
                aria-labelledby="simaScan"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered sima-modal">
                    <div className="modal-content">
                        <div className="modal-header p-1 justify-content-center">
                            <img
                                src="assets/images/logo/sima.svg"
                                height="60"
                                alt=""
                                className="logo-dark"
                            />
                        </div>
                        <div className="modal-body p-5">
                            <div className='login-sima'>
                                <div className='qr-block'>
                                    <QRCode value={qrLink && qrLink} />
                                    {isMobile && (
                                        <div className='sima-btn'>
                                            <Link to={`sima://web-to-app?data=${qrLink && qrLink}`}>SİMA tətbiqinə keçid et</Link>
                                        </div>
                                    )}
                                    <Countdown
                                    overtime={true}
                                        date={Date.now() + 1000  *60* 5}
                                        onComplete={() => {
                                            const newOperationId = uuid();
                                            localStorage.setItem('uuid', JSON.stringify(newOperationId));
                                            setId(newOperationId);
                                        }}
                                        renderer={({ minutes, seconds }) => (
                                            <div>
                                                <span className='countdown'>Bitmə vaxtı: {minutes}</span>:<span>{seconds < 10 ? `0${seconds}` : seconds}</span>
                                            </div>
                                        )}
                                    />

                                </div>
                                <div className='texts-block'>
                                    <p>SİMA mobil tətbiqini açın.</p>
                                    <p>Skan et düyməsini sıxın.</p>
                                    <p>QR kodu skan edərək daxil olun.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimaScan