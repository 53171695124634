import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Ads from '../components/Ads'

function MainLayout({ children }) {
  return (
    <>
      <Navbar />
      <div className="main-content">
        <div className='flex-block'>
            <div className='flex-2'>
              <Ads src="/assets/images/bann2.gif" />
            </div>
            <div className='flex-8'>
              {children}
            </div>
            <div className='flex-2'>
              <Ads src="/assets/images/bann3.gif" />
            </div>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default MainLayout