import React from "react";

function NumberGrid({
  bgImage,
  number,
  handleLinkClick = "",
  handleBuyClick = "",
  cookies = "",
  index = "",
}) {
  let mode = number.mode;
  let color = "#fff";
  let status = "Classic";
  let display = "block";
  switch (mode) {
    case "1":
      color = "#634108";
      status = "Classic";
      display = "none";
      break;
    case "2":
      color = "#706e6d";
      status = "Silver";
      display = "none";
      break;
    case "3":
      color = "#e5b80b";
      status = "Gold";
      break;
    case "4":
      color = "#aab0b3";
      status = "Platinum";
      break;
    default:
      color = "#fff";
  }
  return (
    <div
      className={`job-grid-box card mt-4 ${
        cookies.cart && cookies.cart.some((item) => item.sim === number.sim)
          ? "bookmark-post"
          : ""
      }`}
      key={index}
    >
      <div className="card-body p-4">
        {handleBuyClick && (
          <div className="order-icon">
            <a
              href="#applyNow"
              data-bs-toggle="modal"
              onClick={(event) => handleBuyClick(event, number)}
              className="align-middle"
            >
              Sifariş
            </a>
          </div>
        )}
        <div style={{ display: display }} className="status">
          <i style={{ color: color }} className="mdi mdi-medal"></i>
        </div>
        <div className="gifts">
          {number.gb && number.gb > 0 && (
            <div className="internet-gift gift">
              <i className="mdi mdi-gift-outline"></i>
              <span>{number.gb} GB</span>
            </div>
          )}
          {number.dq && number.dq > 0 && (
            <div className="minute-gift gift">
              <i className="mdi mdi-gift"></i>
              <span>{number.dq} dəq.</span>
            </div>
          )}
        </div>
        {handleLinkClick && (
          <div className="favorite-icon">
            <a
              href="def"
              onClick={(event) => handleLinkClick(event, number)}
              className="align-middle"
            >
              <i className="mdi mdi-star"></i>
            </a>
          </div>
        )}

        <div>
          <a href="https://www.azercell.com/">
            <img
              src="/assets/images/logo/azercell-sq.png"
              className="img-fluid rounded-3"
              alt="Azercell"
            />
          </a>
        </div>
        {/*end col*/}
        <div className="mt-4">
          <a
            href="#applyNow"
            data-bs-toggle="modal"
            onClick={(event) => handleBuyClick(event, number)}
            className="primary-link"
          >
            <h5 className="fs-17 mb-1">
              0{number.sim}{" "}
              {number.eSim === '1' && (
                <span className={`badge bg-soft-primary fs-13 mt-1`}>eSim</span>
              )}
            </h5>
          </a>
          <p className="text-muted">{number.loc}</p>

          <ul className="list-inline">
            {number.oPrice > 0 ? (
              <>
                <li className="list-inline-item">
                  <span className="badge text-decoration-line-through bg-soft-danger fs-13 mt-1">
                    {number.oPrice} ₼
                  </span>
                </li>
                <li className="list-inline-item">
                  <span className="badge bg-soft-success fs-13 mt-1">
                    {number.price} ₼
                  </span>
                </li>
              </>
            ) : (
              <li className="list-inline-item">
                <span className="badge bg-soft-success fs-13 mt-1">
                  {number.price} ₼
                </span>
              </li>
            )}
            <br />
            <li className="list-inline-item">
              <span
                className={`badge bg-soft-${
                  number.fuck == 1 ? "purple" : "info"
                } fs-13 mt-1"`}
              >
                {number.fuck == 1 ? "Faktuarlı" : "Fakturasız"}
              </span>
            </li>
            <li className="list-inline-item">
              <span
                style={{ backgroundColor: color + "aa" }}
                className="badge bg-soft fs-13 mt-1"
              >
                {status}
              </span>
            </li>
            {/* <li className="list-inline-item">
                            <span className="badge bg-soft-blue fs-13 mt-1">Sim</span>
                        </li> */}
          </ul>
        </div>

        {/* <div className="job-grid-content mt-3">
                    <div className="d-flex align-items-center justify-content-between mt-4 border-top pt-3">
                        <p className="text-muted float-start mb-0"><span className="text-dark">Operator :</span> Azercell</p>
                        <div className="text-end">
                            <a
                                href="#applyNow"
                                data-bs-toggle="modal"
                                onClick={(event) => handleBuyClick(event, number)}
                                className="btn btn-success"
                            >
                                Sifariş et 
                            </a>
                        </div>
                    </div>
                </div> */}
        {/*end col*/}
      </div>
    </div>
  );
}

export default NumberGrid;
