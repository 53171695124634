import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import MainLayout from "../layout/MainLayout";
import { Form } from "react-bootstrap";
import AllNumbers from "../components/AllNumbers";
import Favorite from "../components/Favorite";
import BuyNumber from "../components/BuyNumber";
import SimaScan from "../components/SimaScan";
import NumberGrid from "../components/NumberGrid";
import ClassicNumbers from "../components/ClassicNumbers";
import SilverNumbers from "../components/SilverNumbers";
import GoldNumbers from "../components/GoldNumbers";
import PlatiniumNumbers from "../components/PlatiniumNumbers";
import AdsTop from "../components/AdsTop";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdsMini from "../components/AdsMini";

function Home() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const userLS = localStorage.getItem("user");
    if (userLS) {
      const decodedUser = jwtDecode(JSON.parse(userLS));
      setUser(decodedUser.data[0]);
    } else {
      setUser(null);
    }
  }, []);

  const [cookies, setCookie] = useCookies(["cart"]);
  const [values, setValues] = useState({
    prefiks: "",
    num1: "",
    num2: "",
    num3: "",
    num4: "",
    num5: "",
    num6: "",
    num7: "",
  });

  const [searchPage, setSearchPage] = useState(1);
  const [result, setResult] = useState({ results: [], total_count: 0 });
  // const [loadCount, setLoadCount] = useState(0);

  const handleChange = (event) => {
    setSearchPage(1);
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    fetch(`https://mynumber.az/api/search.php?lim=5&off=0`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
        [name]: value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
        notify(data.total_count);
      })
      .catch((error) => console.error("Error:", error));
    if (value && event.target.nextElementSibling) {
      event.target.nextElementSibling.focus();
    }
    if (!value && event.target.previousElementSibling) {
      event.target.previousElementSibling.focus();
    }
  };
  const handleKeyDown = (event) => {
    const { value } = event.target;

    // Əgər backspace düyməsinə basılıbsa və input boşdursa əvvəlki inputa fokuslanır
    if (
      event.key === "Backspace" &&
      !value &&
      event.target.previousElementSibling
    ) {
      event.target.previousElementSibling.focus();
    }
  };

  const handleSearchLoadMore = () => {
    setSearchPage((prevPage) => prevPage + 1);
    console.log(result.total_count / searchPage);
    if (result.total_count / searchPage > 5) {
      fetch(`https://mynumber.az/api/search.php?lim=5&off=${searchPage * 5}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          setResult((prevResult) => ({
            ...prevResult,
            results: [...prevResult.results, ...data.results],
            total_count: data.total_count,
          }));
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  const handleLinkClick = (event, number) => {
    event.preventDefault();
    const updatedCart = Array.isArray(cookies.cart)
      ? cookies.cart.filter((item) => item.sim !== number.sim)
      : [];
    if (cookies.cart === undefined) {
      setCookie("cart", [number]);
    } else {
      if (updatedCart.length === cookies.cart.length) {
        updatedCart.push(number);
        const jobBox = event.target.closest(".job-box");
        if (jobBox) {
          jobBox.classList.add("bookmark-post");
        }
      }
      setCookie("cart", updatedCart);
    }
    setCookie("cart", updatedCart);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();

    const targetDiv = document.querySelector(".bg-light");

    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [buy, setBuy] = useState([]);
  const handleBuyClick = (event, number) => {
    setBuy(number);
  };
  const linkH1 = {
    nomreler: "Nömrələr",
    "online-nomre": "Online nömrə",
    "azercell-nomreler": "Azercell nömrələr",
    "nomre-satisi": "Nömrə satışı",
    "onlayn-nomre-satisi": "Onlayn nömrə satışı",
    "onlayn-nomre-sifarisi": "Onlayn nömrə sifarişi",
    "onlayn-nomre-almaq": "Onlayn nömrə almaq",
  };

  const location = useLocation();
  const path = location.pathname;
  const pathAfterRoot = path.substring(1);
  const pageTitle = linkH1[pathAfterRoot] || "MyNumber onlayn nömrə satışı";
  const notify = (data) => {
    if (data > 0) {
      toast.success("Axtarış üzrə " + data + " nəticə tapıldı", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClick: handleOnSubmit,
        transition: Bounce,
      });
    } else {
      toast.warn("Axtarış üzrə nəticə tapılmadı", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };
  return (
    <MainLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="MyNumber nömrə satışı | Nömrələr " />
        <meta property="og:title" content="MyNumber onlayn nömrə satışı" />
      </Helmet>
      <div className="page-content">
        <section className="bg-home" id="home">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="text-center text-white mb-5">
                  <h1 className="display-5 fw-semibold mb-3">
                    <span className="bg-txt-slc">İstədiyin </span>
                    <span className="bg-txt-slc">Azercell </span>
                    <span className="bg-txt-slc">nömrəni </span>
                    <span className="bg-txt-slc">indi al!</span>
                    <span className="bg-txt-slc"> 10,000+ </span>
                    <span className="bg-txt-slc">seçilmiş</span>
                    <span className="bg-txt-slc"> nömrə.</span>
                  </h1>
                  <p className="fs-17">
                    Nömrəni istəlinən hissəsinə görə axtar
                  </p>
                </div>
              </div>
            </div>
            <Form onSubmit={handleOnSubmit}>
              <Form.Group className="mb-3 form-flex">
                <div className="d-flex pre-select">
                  <Form.Select
                    aria-label="Default select example"
                    className="pre"
                    name="prefiks"
                    onChange={handleChange}
                  >
                    <option value="">Sim</option>
                    <option value={10}>010</option>
                    <option value={50}>050</option>
                    <option value={51}>051</option>
                  </Form.Select>
                </div>
                <div className="d-flex num-inputs">
                  <Form.Control
                    id="1"
                    placeholder="2"
                    className="num-single"
                    name="num1"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Form.Control
                    id="2"
                    placeholder="1"
                    className="num-single"
                    name="num2"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Form.Control
                    id="3"
                    placeholder="1"
                    className="num-single"
                    name="num3"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />

                  <Form.Control
                    id="4"
                    placeholder="2"
                    className="num-single"
                    name="num4"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Form.Control
                    id="5"
                    placeholder="1"
                    className="num-single"
                    name="num5"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />

                  <Form.Control
                    id="6"
                    placeholder="2"
                    className="num-single"
                    name="num6"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <Form.Control
                    id="7"
                    placeholder="1"
                    className="num-single"
                    name="num7"
                    maxLength={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </Form.Group>
              {Object.values(values).some((value) => value !== "") && (
                <button className="result-bt">
                  <i className="mdi mdi-magnify"></i> Axtar
                </button>
              )}
              <ToastContainer />
            </Form>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1440"
              height="150"
              preserveAspectRatio="none"
              viewBox="0 0 1440 220"
            >
              <g mask='url("#SvgjsMask1004")' fill="none">
                <path
                  d="M 0,213 C 288,186.4 1152,106.6 1440,80L1440 250L0 250z"
                  fill="rgba(255, 255, 255, 1)"
                ></path>
              </g>
              <defs>
                <mask id="SvgjsMask1004">
                  <rect width="1440" height="250" fill="#ffffff"></rect>
                </mask>
              </defs>
            </svg>
          </div>
        </div>
        {/* END SHAPE 
    
  

        {/* START Number-LIST */}
        <section className="section bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {result.results == null || result.results.length === 0 ? (
                  <AdsTop />
                ) : null}
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {result.results &&
                result.results.map((number, index) => (
                  <>
                    {index === 0 && (
                      <h4 className="title">
                        Axtarışınız üzrə {result.total_count} nömrə tapıldı
                      </h4>
                    )}
                    <div className="col-lg-4 col-md-6 mt-4">
                      <NumberGrid
                        number={number}
                        index={index}
                        handleBuyClick={handleBuyClick}
                        handleLinkClick={handleLinkClick}
                        cookies={cookies}
                      />
                    </div>
                  </>
                ))}
              {result.results && result.total_count / searchPage > 5 && (
                <div className="text-center mt-4 pt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleSearchLoadMore}
                  >
                    Daha Çox <i className="uil uil-arrow-right"></i>
                  </button>
                </div>
              )}

              {/*end col*/}
            </div>
            {/*end row*/}
            {result.results == null || result.results.length === 0 ? (
              <div className="row justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <ul
                      className="job-list-menu nav nav-pills nav-justified flex-column flex-sm-row mb-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      {cookies.cart && cookies.cart.length > 0 && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="freelancer-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#freelancer"
                            type="button"
                            role="tab"
                            aria-controls="freelancer"
                            aria-selected="false"
                          >
                            Seçilmişlər
                          </button>
                        </li>
                      )}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="recent-jobs-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#recent-jobs"
                          type="button"
                          role="tab"
                          aria-controls="recent-jobs"
                          aria-selected="true"
                        >
                          <i className="mdi mdi-sim"></i> Nömrələr
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="Classic-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#classic"
                          type="button"
                          role="tab"
                          aria-controls="classic"
                          aria-selected="false"
                        >
                          CLASSIC
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="silver-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#silver"
                          type="button"
                          role="tab"
                          aria-controls="silver"
                          aria-selected="false"
                        >
                          SILVER
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="gold-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#gold"
                          type="button"
                          role="tab"
                          aria-controls="gold"
                          aria-selected="false"
                        >
                          GOLD
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="platin-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#platin"
                          type="button"
                          role="tab"
                          aria-controls="platin"
                          aria-selected="false"
                        >
                          PLATINUM
                        </button>
                      </li>
                    </ul>
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
                <div className="row">
                  {Object.values(values).some((value) => value !== "") && (
                    <div className="alert alert-danger">
                      Axtarışınız üzrə nömrə tapılmadı
                    </div>
                  )}

                  <div className="col-lg-12">
                    
                    <div className="tab-content" id="pills-tabContent">
                      <div className="col-lg-4 col-md-6 mt-4"> 
                        <AdsMini/>
                      </div>
                      <div
                        className="tab-pane fade show active"
                        id="recent-jobs"
                        role="tabpanel"
                        aria-labelledby="recent-jobs-tab"
                      >
                        <AllNumbers handleBuyClick={handleBuyClick} />
                      </div>

                      <div
                        className="tab-pane fade"
                        id="classic"
                        role="tabpanel"
                        aria-labelledby="classic-tab"
                      >
                        <ClassicNumbers handleBuyClick={handleBuyClick} />
                      </div>

                      <div
                        className="tab-pane fade"
                        id="silver"
                        role="tabpanel"
                        aria-labelledby="silver-tab"
                      >
                        <SilverNumbers handleBuyClick={handleBuyClick} />
                      </div>

                      <div
                        className="tab-pane fade"
                        id="gold"
                        role="tabpanel"
                        aria-labelledby="gold-tab"
                      >
                        <GoldNumbers handleBuyClick={handleBuyClick} />
                      </div>

                      <div
                        className="tab-pane fade"
                        id="platin"
                        role="tabpanel"
                        aria-labelledby="platin-tab"
                      >
                        <PlatiniumNumbers handleBuyClick={handleBuyClick} />
                      </div>
                      {/*end freelancer-tab*/}
                      {cookies.cart && cookies.cart.length > 0 && (
                        <div
                          className="tab-pane fade"
                          id="freelancer"
                          role="tabpanel"
                          aria-labelledby="freelancer-tab"
                        >
                          <Favorite handleBuyClick={handleBuyClick} />
                        </div>
                      )}
                      {/*end part-time-tab*/}
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </div>
            ) : null}

            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
        {/* END JOB-LIST */}

        {/* START PROCESS */}
        <section className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-title me-5 tts">
                  <h3 className="title">Mynumber rahatlığını hiss et!</h3>
                  <p className="text-muted">
                    Mynumber.az sizə asan və sürətli imkanlar verir. Addımları
                    təkrarlayaraq istədiyiniz nömrəni asanlıqla əldə edə
                    bilərsiniz.
                  </p>
                  <div
                    className="process-menu nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <div className="d-flex">
                        <div className="number flex-shrink-0">1</div>
                        <div className="flex-grow-1 text-start ms-3">
                          <h5 className="fs-18">Sürətli Axtarış</h5>
                          <p className="text-muted mb-0">
                            Axtarmaq istədiyiniz nömrənizin istənilən bir
                            hissəsini daxil edərək, sürətli şəkildə nəticəni
                            görə bilərsiniz.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <div className="d-flex">
                        <div className="number flex-shrink-0">2</div>
                        <div className="flex-grow-1 text-start ms-3">
                          <h5 className="fs-18">Bir kliklə Sifariş</h5>
                          <p className="text-muted mb-0">
                            "Sifariş et" düyməsinə klik edərək, sifariş təsdiqi
                            pəncərəsini görə bilərsiniz.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      href="#v-pills-messages"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <div className=" d-flex">
                        <div className="number flex-shrink-0">3</div>
                        <div className="flex-grow-1 text-start ms-3">
                          <h5 className="fs-18">
                            SİMA ilə giriş və ya qeydiyyatsız
                          </h5>
                          <p className="text-muted mb-0">
                            SİMA ilə giriş edərək sifarişi təsdiq etmək daha
                            rahatdır və ya qeydiyyatsız şəkildə formu doldurarq
                            sifarişinizi təsdiq edə bilərsiniz.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-6">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <img
                      src="assets/images/process-01.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <img
                      src="assets/images/process-02.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <img
                      src="assets/images/process-03.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <h1>{pageTitle}</h1>
            </div>{" "}
            {/*end row*/}
          </div>
          {/*end container*/}
        </section>
        {/* END PROCESS */}

        {/* START APPLY MODAL */}
        <BuyNumber number={buy} />

        {!user && <SimaScan />}
      </div>
      {/* End Page-content */}

      {/* START SUBSCRIBE */}
    </MainLayout>
  );
}

export default Home;
