import React from 'react'

function AdsMini() {
  return (
    <div className='job-grid-box card mt-4'>
        <img
            className=""
            src="/assets/images/miniBann.jpeg"
            alt="Sevdiyiniz nömrə sərfəli təklif ilə"
            style={{width:'100%'}}
          />
    </div>
  )
}

export default AdsMini